import { IsNumber } from 'class-validator';
import { IsFirestoreUUID } from '../../validators/validators';

/**
 * Definition of module content.
 */
export class FlowDefinitionModuleContentDto {
  /** Unique identifier of the module content */
  @IsFirestoreUUID()
  readonly id: string;

  /** Order of the module content in the module */
  @IsNumber()
  readonly order: number;

  constructor(dto: FlowDefinitionModuleContentDto) {
    this.id = dto.id;
    this.order = dto.order;
  }
}
