import { PropsWithChildren } from 'react';
import Error404 from 'src/app/pages/Error404';
import { AclResource } from 'src/services/acl/aclResource';
import { AclAccessMode, useCanAccessResources } from 'src/services/acl/aclApi';

interface IAclRouterGuardProps {
  resources: AclResource[];
  mode?: AclAccessMode;
}

/**
 * This component is used to guard routes that require ACL access.
 * It checks if the user has access to the requested resources.
 * Uses the {@link useCanAccessResources} hook to check access.
 *
 * @param resources - Resources requested
 * @param mode - Access mode (defaults to "any")
 * @param children - Child components
 */
export function AclRouterGuard({
  resources,
  mode,
  children,
}: PropsWithChildren<IAclRouterGuardProps>) {
  const canAccess = useCanAccessResources(resources, mode);
  if (!canAccess) {
    return <Error404 />;
  }

  return <>{children}</>;
}
