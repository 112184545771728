import { useMemo, useRef, useSyncExternalStore } from 'react';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
  QuerySnapshot,
  where,
} from '@firebase/firestore';
import { CollectionQueryArgs } from 'src/services/database/types';
import { Resource } from 'src/utils/resource';
import {
  composeQueryConstraints,
  useMemoizedQueryArgs,
} from 'src/services/database/utils';
import { getPathForWorkspaces } from 'src/services/database/paths';
import { useFirestoreQuerySubscription } from 'src/services/database/useFirestoreQuerySubscription';
import { WorkspaceConverter } from 'src/services/database/Workspaces/converter';
import { useUserId } from 'src/utils/userContent/hooks';
import { useFirestoreDocSubscription } from 'src/services/database/useFirestoreDocSubscription';
import { useWorkspaceId } from 'src/utils/resource.hooks';
import { WorkspaceDto } from 'src/services/database/Workspaces/dto/workspace.dto';

/**
 * Fetches a workspace document from Firestore asynchronously. This function is
 * mostly used when a cloud function is updating the workspace, and we need to
 * fetch the updated workspace.
 * @param workspaceId - ID of the workspace to fetch.
 * @returns A promise that resolves with a {@link WorkspaceDto} document.
 */
export async function getWorkspaceDoc(workspaceId: string) {
  const workspaceDoc = doc(
    getFirestore(),
    getPathForWorkspaces(workspaceId),
  ).withConverter(WorkspaceConverter);

  const workspaceDocSnapshot = await getDoc(workspaceDoc);
  return workspaceDocSnapshot.data();
}

/**
 * Subscribes to Firestore and returns a {@link WorkspaceDto} document.
 * @param workspaceId - ID of the workspace to fetch.
 * @returns A Firestore document reference.
 */
export function useWorkspaceDoc(workspaceId: string) {
  const instanceQuery = useMemo(
    () =>
      workspaceId
        ? doc(getFirestore(), getPathForWorkspaces(workspaceId))
        : undefined,
    [workspaceId],
  );
  const converter = useRef(WorkspaceConverter);
  const store = useFirestoreDocSubscription(converter.current, instanceQuery);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(
    () => ({
      result,
      error: store.error,
    }),
    [result, store.error],
  );
}

/**
 * Subscribes to Firestore and returns a collection of {@link WorkspaceDto} documents.
 * The documents are filtered by the current user's ID and so the user must be authenticated to use this hook.
 * @param queryArg - Query argument that specifies which documents are returned.
 * @returns A Firestore collection reference.
 */
export function useWorkspaceCol(
  queryArg?: CollectionQueryArgs<WorkspaceDto>,
): Resource<QuerySnapshot<WorkspaceDto>> {
  const userId = useUserId();
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);
  const queryMemo = useMemo(
    () =>
      userId
        ? query(
            collection(getFirestore(), getPathForWorkspaces()),
            where(`members.${userId}`, '!=', null),
            ...composeQueryConstraints({ filterBy, filterByIds, sort }),
          )
        : undefined,
    [filterBy, filterByIds, sort, userId],
  );

  const converter = useRef(WorkspaceConverter);
  const store = useFirestoreQuerySubscription(converter.current, queryMemo);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(
    () => ({
      result,
      error: store.error,
    }),
    [result, store.error],
  );
}

/**
 * Get active workspace document from Firebase.
 * Uses the workspace ID via the `useWorkspaceId` hook.
 * @see useWorkspaceId
 * @returns Workspace document
 */
export function useWorkspace() {
  const workspaceId = useWorkspaceId();
  const doc = useWorkspaceDoc(workspaceId);

  return useMemo(() => doc.result?.data(), [doc.result]);
}

/**
 * Get the count of workspaces for the current user.
 * @see useWorkspacesForUser
 * @returns Count of workspaces
 */
export function useWorkspacesCount() {
  const { result, error } = useWorkspaceCol();
  return {
    count: result?.docs.length,
    error,
  };
}
