import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ParamsFromPath, PathParams } from 'src/app/router/routeValidation';
import {
  useFlowDefinitionId,
  useFlowInstanceId,
  useSessionId,
  useWorkspaceId,
} from 'src/utils/resource.hooks';
import { Path } from './routes';

/**
 * Returns all typed URL parameters.
 */
export function useTypedParams() {
  return useParams() as PathParams;
}

/**
 * @template TPath - A valid `Path`
 * @returns Typed path params of a single {@link Path}
 */
export function useTypedPathParams<TPath extends Path>() {
  return useParams() as unknown as ParamsFromPath<TPath>;
}

/**
 * This function is used to fill params automatically if they are not provided.
 * It's used in most "typed" functions.
 * @template TPath - A valid `Path`
 * @param params - Path params
 * @param shouldAutofill - Whether to automatically fill in workspace and flow slugs if they are not provided. Default is `true`.
 */
export function useTypedAutofillParams(
  params: PathParams = {},
  shouldAutofill = true,
) {
  const workspaceId = useWorkspaceId();
  const flowDefinitionId = useFlowDefinitionId();
  const flowInstanceId = useFlowInstanceId();
  const sessionId = useSessionId();

  return useMemo(() => {
    const filledParams: { [key: string]: string } = { ...params };
    if (shouldAutofill) {
      filledParams.workspaceId ??= workspaceId;
      filledParams.flowDefinitionId ??= flowDefinitionId;
      filledParams.flowInstanceId ??= flowInstanceId;
      filledParams.sessionId ??= sessionId;
    }
    return filledParams;
  }, [
    flowDefinitionId,
    flowInstanceId,
    params,
    sessionId,
    shouldAutofill,
    workspaceId,
  ]);
}
