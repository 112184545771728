import {
  addDoc,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from '@firebase/firestore';
import { useCallback } from 'react';
import { UUID } from 'src/@types/common';
import {
  useEnrollment,
  useEnrollmentId,
  useEnrollmentStatus,
} from 'src/services/database/Enrollments/getters';
import {
  Enrollment,
  EnrollmentConverter,
  EnrollmentStatus,
  EnrollmentToDb,
} from 'src/services/database/Enrollments/utils';
import { getPathForEnrollments } from 'src/services/database/paths';
import { catchFirestoreError } from 'src/services/database/utils';

/**
 * Creates an {@link Enrollment} document in Firestore that serves as a record of the user's participation in a flow instance.
 * @param userId - The user's account ID.
 * @param workspaceId - The workspace ID where the enrollment should be displayed.
 * @param flowDefinitionId - The flow definition's ID, used for easier lookup.
 * @param flowInstanceId - ID of the flow instance ID where the user has participated.
 * @returns Firestore document reference
 */
export async function createEnrollmentDoc(
  userId: UUID,
  workspaceId: UUID,
  flowDefinitionId: UUID,
  flowInstanceId: UUID,
) {
  const ref = collection(getFirestore(), getPathForEnrollments()).withConverter(
    EnrollmentConverter,
  );
  const enrollment: EnrollmentToDb = {
    createdAt: serverTimestamp(),
    creatorId: userId,
    flowDefinitionId,
    flowInstanceId,
    status: EnrollmentStatus.NotStarted,
    workspaceId,
    sessionProgress: [],
  };
  addDoc(ref, enrollment).catch((error) => {
    catchFirestoreError(error, {
      data: {
        userId,
        workspaceId,
        flowDefinitionId,
        flowInstanceId,
      },
    });
  });
  return ref;
}

/**
 * Updates an existing {@link Enrollment} document in Firestore.
 * @param docId - ID of the enrollment document
 * @param partialPayload - Partial changes to the document
 */
export async function updateEnrollmentDoc(
  docId: UUID,
  partialPayload: Partial<Enrollment>,
) {
  const ref = doc(getFirestore(), getPathForEnrollments(docId)).withConverter(
    EnrollmentConverter,
  );
  setDoc(ref, partialPayload, { merge: true }).catch((error) =>
    catchFirestoreError(error, {
      data: {
        docId,
        partialPayload,
      },
    }),
  );
  return ref;
}

// TODO [KS] Instead of managing the status, calculate it based on the session progress.

/**
 * Updates the status of an enrollment document.
 * @param id - ID of the enrollment document
 * @param status - New status to set
 */
export function updateEnrollmentStatus(id: UUID, status: EnrollmentStatus) {
  return updateEnrollmentDoc(id, { status });
}

/**
 * Updates the status of the enrollment with the given ID.
 * @param id - ID of the enrollment document
 * @param status - New status to set
 */
function useUpdateEnrollmentStatus(id: UUID, status: EnrollmentStatus) {
  const currentStatus = useEnrollmentStatus(id);
  return useCallback(
    function useUpdateEnrollmentStatus() {
      if (!currentStatus || currentStatus === status) {
        return;
      } else if (currentStatus === EnrollmentStatus.Completed) {
        console.error(
          `Cannot update enrollment status to ${status} because it is already completed.`,
        );
        return;
      }

      return updateEnrollmentStatus(id, status);
    },
    [id, status, currentStatus],
  );
}

/**
 * Sets the status of the enrollment with the given ID to "In Progress".
 */
export function useSetActiveEnrollmentInProgress() {
  const id = useEnrollmentId() || '';
  return useUpdateEnrollmentStatus(id, EnrollmentStatus.InProgress);
}

/**
 * Sets the status of the enrollment with the given ID to "Completed".
 */
export function useSetActiveEnrollmentComplete() {
  const id = useEnrollmentId() || '';
  return useUpdateEnrollmentStatus(id, EnrollmentStatus.Completed);
}

/**
 * Resets session progress for the enrollment with the given session definition ID.
 */
export function useEnrollmentResetSessionProgress(sessionId: UUID) {
  const enrollment = useEnrollment();

  return useCallback(async () => {
    if (!enrollment) {
      return;
    }

    const sessionProgress =
      enrollment
        .data()
        .sessionProgress?.filter((x) => x.sessionId !== sessionId) || [];

    return updateEnrollmentDoc(enrollment.id, { sessionProgress });
  }, [enrollment, sessionId]);
}
