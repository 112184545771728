import { AclResource } from 'src/services/acl/aclResource';
import { useWorkspace } from 'src/services/database/Workspaces/getters';
import { useUserId } from 'src/utils/userContent/hooks';
import { useIsGuestUser } from 'src/utils/auth/hooks';

/**
 * This file contains the API for the ACL layer. It's currently a temporary
 * solution that will be replaced by a more robust solution in the future.
 * In its current form, it's only used for limiting access to certain features
 * for guest users. It handles specific resource access rather than using an
 * access control list (ACL) to control access to all resources.
 */

/**
 * Checks if the user the creator of the workspace.
 */
function useIsWorkspaceCreator(): boolean {
  const userId = useUserId();
  const workspace = useWorkspace();
  return workspace?.createdBy === userId;
}

export enum AclAccessMode {
  All = 'all',
  Any = 'any',
  None = 'none',
}

/**
 * Checks if the user is allowed to access specific resources, based on their
 * role and workspace context. Access mode can be "all", "any" or "none". The
 * default mode is "any".
 *
 * "all" mode means that the user must have access to all resources in the list.
 * "any" mode means that the user must have access to at least one resource in
 * the list.
 * "none" mode means that the user must not have access to any resource in the
 * list.
 *
 * Currently, the function only checks if the user
 * is a guest user and if they are in their own workspace, regardless of the
 * resource requested. Later on, the function will be updated to use an ACL to
 * control access to all resources.

 * @param resources - Resources requested
 * @param mode - Access mode (defaults to "any")
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   const canAccess = useCanAccessResource(
 *     [
 *       AclResource.WorkspaceSettings,
 *       AclResource.WorkspaceMembers
 *     ],
 *     AclAccessMode.Any,
 *   );
 *   return <div>{canAccess ? "Access allowed" : "Access denied"}</div>;
 * }
 * ```
 */
export function useCanAccessResources(
  resources: AclResource[],
  mode: AclAccessMode = AclAccessMode.Any,
): boolean {
  const isRegularUser = !useIsGuestUser();
  const isWorkspaceCreator = useIsWorkspaceCreator();

  const hasAccess = isRegularUser || isWorkspaceCreator;
  return mode === AclAccessMode.None ? !hasAccess : hasAccess;
}

/**
 * Helper function for one specific resource. It's using the
 * {@link useCanAccessResources} hook, and its "mode" is always "all".
 * @param resource - The resource to check access to
 */
export function useCanAccessResource(resource: AclResource) {
  return useCanAccessResources([resource], AclAccessMode.All);
}
