import { useCallback, useEffect, useMemo } from 'react';
import { useUserId } from 'src/utils/userContent/hooks';
import {
  useFlowDefinitionId,
  useFlowInstanceId,
  useWorkspaceId,
} from 'src/utils/resource.hooks';
import { buildSimpleQuery } from 'src/services/database/utils';
import {
  createEnrollmentDoc,
  useEnrollmentCol,
} from 'src/services/database/Enrollments';
import useAsync from 'src/utils/useAsync';

export function useEnsureEnrollment() {
  const creatorId = useUserId();
  const workspaceId = useWorkspaceId();
  const flowDefinitionId = useFlowDefinitionId();
  const flowInstanceId = useFlowInstanceId();
  const query = useMemo(
    () =>
      buildSimpleQuery({
        creatorId,
        workspaceId,
        flowInstanceId,
      }),
    [creatorId, workspaceId, flowInstanceId],
  );

  const enrollmentsCount = useEnrollmentCol(query).result?.docs.length;

  const handleInit = useCallback(
    () =>
      createEnrollmentDoc(
        creatorId,
        workspaceId,
        flowDefinitionId,
        flowInstanceId,
      ),
    [flowDefinitionId, flowInstanceId, creatorId, workspaceId],
  );

  // TODO [low] handle error & show error
  const request = useAsync(handleInit, {
    isImmediate: false,
    isParallel: false,
  });

  useEffect(() => {
    if (enrollmentsCount === 0 && request.status === 'idle') {
      handleInit();
    }
  }, [enrollmentsCount, handleInit, request.status]);

  return enrollmentsCount && enrollmentsCount > 0;
}
