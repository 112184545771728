import { produce } from 'immer';
import { CSSProperties, useMemo } from 'react';
import clsx from 'clsx';
import { AlignRightSimple, Cog, Question } from 'src/app/components/icons';
import { VITE_EMAIL_SUPPORT } from 'src/env';
import {
  useSidebarMenuState,
  useSidebarState,
} from 'src/state/sidebar/sidebar';
import { fillParent } from 'src/styles/utils.css';
import { useFocusState } from 'src/utils/useFocusState';
import { Tooltip } from 'src/app/components/shared/Tooltip';
import { useCanAccessResource } from 'src/services/acl/aclApi';
import { AclResource } from 'src/services/acl/aclResource';
import {
  sidebarButton,
  sidebarButtonActive,
  sidebarVars,
} from '../../Sidebar.css';
import { SidebarMenu } from '../../SidebarMenu';
import {
  allRounded,
  leftRounded,
  menuItemIcon,
} from '../../SidebarMenu/SidebarMenu.css';
import useSidebarMenu from '../../SidebarMenu/useSidebarMenu';
import { CogSpinAnimation } from './CogSpinAnimation';
import { WorkspaceSettingsModal } from './modals/WorkspaceSettingsModal';

export function Settings() {
  const MENU_ID = 'workspaceSettings';
  const [isOpen, setIsOpen] = useSidebarMenuState(MENU_ID);
  const isContentOpen = !!useSidebarState().contentId;

  const { getAnchorProps, getFloatingProps } = useSidebarMenu({
    isOpen,
    setIsOpen,
    placement: 'right-end',
    offsetOptions: { mainAxis: 6 },
  });

  const sidebarProps = getFloatingProps();
  // Offsets the sidebar menu with half of the sidebar's button slot size.
  const sidebarStyle = useMemo<CSSProperties | undefined>(() => {
    const { floatingContext, style } = sidebarProps;
    const { transform } = style ?? {};
    if (style && transform) {
      return produce(style, (draft) => {
        const { x, y } = floatingContext;
        const SIDEBAR_EDGE_OFFSET = 10;
        draft.transform = `translate(${
          x + SIDEBAR_EDGE_OFFSET
        }px, calc(${y}px - ${sidebarVars.buttonSize} / 2))`;
      });
    }
  }, [sidebarProps]);

  const [isButtonFocused, focusCallbacks] = useFocusState();
  const hasAccessToSettings = useCanAccessResource(
    AclResource.WorkspaceSettings,
  );

  return (
    <>
      <Tooltip.Root placement="right">
        <Tooltip.Anchor>
          <button
            type="button"
            {...focusCallbacks}
            onClick={() => setIsOpen(!isOpen)}
            title="Toggle workspace settings"
            className={clsx(sidebarButton, isOpen && sidebarButtonActive)}
            {...getAnchorProps()}
          >
            <CogSpinAnimation
              isFocused={isButtonFocused}
              className={fillParent}
            >
              <Cog
                style={{
                  display: 'block', // Gets rid of SVG's phantom line height
                  width: '24px',
                  height: '24px',
                  color: 'inherit',
                }}
              />
            </CogSpinAnimation>
          </button>
        </Tooltip.Anchor>
        {!isOpen && <Tooltip.Content>Workspace settings</Tooltip.Content>}
      </Tooltip.Root>
      {isOpen && (
        <SidebarMenu.Root
          {...getFloatingProps()}
          title="Settings"
          className={isContentOpen ? leftRounded : allRounded}
          style={sidebarStyle}
        >
          {hasAccessToSettings && (
            <SidebarMenu.ItemWithModal
              icon={<AlignRightSimple className={menuItemIcon} />}
              label="Workspace basics"
              menuId={MENU_ID}
              modalId="Workspace basics"
              modal={(props) => (
                <WorkspaceSettingsModal {...props} className={fillParent} />
              )}
            />
          )}
          <SidebarMenu.Item
            icon={<Question />}
            label="Need help? Contact us!"
            onClick={() => {
              window.location.href = `mailto:${VITE_EMAIL_SUPPORT}`;
            }}
          />
        </SidebarMenu.Root>
      )}
    </>
  );
}
