import { PropsWithChildren } from 'react';
import { ResourceLoader } from 'src/app/components/shared/ResourceLoader/ResourceLoader';
import Error404 from 'src/app/pages/Error404';
import { useFlowDefinitionDoc } from 'src/services/database/FlowDefinitions/getters';
import { useCanEditOrDuplicateFlowDefinition } from 'src/utils/flow/hooks';
import { useFlowDefinitionId } from 'src/utils/resource.hooks';

/**
 * Fetches the flow definition, and renders `children` through `<ResourceLoader />` if
 * the user has permission to edit the flow (regardless of whether the session is visible).
 * Otherwise, a 404 page is rendered.
 */
export function SessionEditorAccessGuard({
  children,
}: PropsWithChildren<unknown>) {
  const flow = useFlowDefinitionDoc(useFlowDefinitionId());
  const canEditSession = useCanEditOrDuplicateFlowDefinition();

  return (
    <ResourceLoader resource={flow}>
      {canEditSession ? children : <Error404 />}
    </ResourceLoader>
  );
}
