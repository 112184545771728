import { IsNumber, IsObject, IsOptional } from 'class-validator';
import { DragDropManager } from '@features/canvas/services/DragDropManager';
import { UUID } from 'src/@types/common';
import { UnknownPartial } from './types';
import {
  IsDraggableType,
  IsFirestoreUUID,
  IsUUIDArray,
} from './validators/validators';

/**
 * Intended to be used by stateful canvas widgets and canvas contents (i.e. user-created items).
 * @internal
 */
export class _CanvasItemBaseDto<TContent = UnknownPartial> {
  /** The item's unique ID (it should match the Firestore document ID). */
  @IsFirestoreUUID()
  readonly id: UUID;

  /**
   * This ID is assigned to the canvas item in the canvas editor at the moment the item is created.
   *
   * At the moment of creation, this ID can be a random UUID, or a descriptive static ID set by the user.
   *
   * This ID is used by
   * - the session's facilitation logic, e.g. highlighting the element when a certain nudge is active.
   * - the canvas item's editor in the session editor page.
   *
   * ---
   *
   * This ID is **temporarily** used by the flow instance setters when generating new flow instances.
   * Because currently
   * - canvas items are created at the moment of flow instance creation, not in the editor, and
   * - the created items have randomized IDs,
   * there's no way to establish the parent-child relationships between widgets and canvas content items.
   * Relying on the `id` circumvents this issue until the canvas editor is fully implemented.
   */
  @IsFirestoreUUID()
  readonly semanticId: UUID;

  /** ID of the flow instance where the canvas content was created. */
  @IsFirestoreUUID()
  readonly flowInstanceId: UUID;

  /** ID of the canvas session where the canvas content was created. */
  @IsFirestoreUUID()
  readonly sessionId: UUID;

  /**
   * Custom item content. Its format will depend on the component's use case.
   * E.g. a canvas note with custom background colors will have different props than a simple embedded date-picker.
   */
  @IsObject()
  readonly content: TContent;

  /**
   * UTC time when the item was created.
   */
  @IsNumber()
  readonly createdAt: number;

  /** ID of user who created this content. */
  @IsFirestoreUUID()
  readonly createdBy: UUID;

  /**
   * This type determines whether the rendered item is draggable,
   * or if it acts as a drop zone that accepts other draggable or droppable items.
   *
   * This value is used for validating the `parentNodes` of a draggable item
   * against invalid ancestry chains.
   */
  @IsOptional()
  @IsDraggableType()
  readonly draggableType?: DragDropManager.Drag.DraggableType;

  /**
   * UTC time when the item was last edited by anyone.
   */
  @IsNumber()
  readonly editedAt: number;

  /** Array of user IDs who have edited this content after its creation. (also includes the creator's user ID) */
  @IsUUIDArray()
  readonly editedBy: UUID[];

  /**
   * UTC time when any user last moved the item, if the content can be positioned.
   * */
  @IsOptional()
  @IsNumber()
  readonly lastMovedAt?: number;

  /**
   * Ancestry tree of the draggable item that contains the IDs of all its parents,
   * starting from its immediate parent up to the root drop zone.
   *
   * Its main use case is so draggable children can hide the original component
   * not only when they are dragged, but also when their parent drop zone is dragged.
   */
  @IsOptional()
  @IsUUIDArray()
  readonly parentNodes?: UUID[];

  /** Item's X coordinate in `px` relative to its immediate parent, if the content can be positioned. */
  @IsOptional()
  @IsNumber()
  readonly x?: number;

  /** Item's Y coordinate in `px` relative to its immediate parent, if the content can be positioned. */
  @IsOptional()
  @IsNumber()
  readonly y?: number;

  constructor(dto: _CanvasItemBaseDto<TContent>) {
    this.content = dto.content;
    this.createdAt = dto.createdAt;
    this.createdBy = dto.createdBy;
    this.draggableType = dto.draggableType;
    this.editedAt = dto.editedAt;
    this.editedBy = dto.editedBy;
    this.flowInstanceId = dto.flowInstanceId;
    this.id = dto.id;
    this.lastMovedAt = dto.lastMovedAt;
    this.parentNodes = dto.parentNodes;
    this.semanticId = dto.semanticId;
    this.sessionId = dto.sessionId;
    this.x = dto.x;
    this.y = dto.y;
  }
}
