import {
  IsInt,
  IsNumber,
  IsObject,
  IsOptional,
  IsPositive,
} from 'class-validator';
import { DragDropManager } from '@features/canvas/services/DragDropManager';
import { UUID } from 'src/@types/common';
import { UnknownPartial } from 'src/services/database/types';
import {
  IsDraggableType,
  IsFirestoreUUID,
  IsUUIDArray,
} from 'src/services/database/validators/validators';

/**
 * Used for creating pre-defined widget configs, and default canvas content DTOs
 * in session definitions.
 *
 * Configurations are used as templates at the moment of flow instance creation
 * to instantiate actual canvas content and widget documents in the database.
 *
 * @internal This partial DTO is extended by other DTOs, and is not meant to be used on its own.
 */
export class _CanvasItemConfigBaseDto<TContent = UnknownPartial> {
  /**
   * This ID is assigned to the canvas item in the canvas editor at the moment the item is created.
   *
   * At the moment of creation, this ID can be a random UUID, or a descriptive static ID set by the user.
   *
   * This ID is used by
   * - the session's facilitation logic, e.g. highlighting the element when a certain nudge is active.
   * - the canvas item's editor in the session editor page.
   *
   * ---
   *
   * This ID is **temporarily** used by the flow instance setters when generating new flow instances.
   * Because currently
   * - canvas items are created at the moment of flow instance creation, not in the editor, and
   * - the created items have randomized IDs,
   * there's no way to establish the parent-child relationships between widgets and canvas content items.
   * Relying on the `id` circumvents this issue until the canvas editor is fully implemented.
   */
  @IsFirestoreUUID()
  readonly semanticId: UUID;

  /**
   * The unique configuration fields of the item.
   */
  @IsObject()
  readonly content: TContent;

  /**
   * The ancestry tree of the item inside the canvas.
   */
  @IsUUIDArray()
  readonly parentNodes: UUID[];

  /**
   * Specifies whether the widget's role in the drag-drop system.
   * This field is **required** if `isMovable` is `true`.
   */
  @IsOptional()
  @IsDraggableType()
  readonly draggableType?: DragDropManager.Drag.DraggableType;

  /**
   * Optional coordinate of the item, if it is positioned.
   * This field is **required** if `isMovable` is `true`.
   */
  @IsOptional()
  @IsNumber()
  readonly x?: number;

  /**
   * Optional coordinate of the item, if it is positioned.
   * This field is **required** if `isMovable` is `true`.
   */
  @IsOptional()
  @IsNumber()
  readonly y?: number;

  /**
   * Optional stack index of the item. Should be a positive integer.
   */
  @IsOptional()
  @IsInt()
  @IsPositive()
  readonly z?: number;

  constructor(dto: _CanvasItemConfigBaseDto<TContent>) {
    this.semanticId = dto.semanticId;
    this.content = dto.content;
    this.parentNodes = dto.parentNodes;
    this.draggableType = dto.draggableType;
    this.x = dto.x;
    this.y = dto.y;
    this.z = dto.z;
  }
}
