import { PropsWithChildren, ReactNode } from 'react';
import {
  resourceLoaderErrorCss,
  resourceLoaderLoadingCss,
  resourceLoaderLoadingSpinnerCss,
} from 'src/app/components/shared/ResourceLoader/ResourceLoader.css';
import { Spinner } from 'src/app/components/shared/Spinner';
import { Resource } from 'src/utils/resource';

export type ResourceWithDocs<T> = Resource<{ docs: T[] }>;
export type ResourceWithData<T> = Resource<{ data: T }>;

export interface IResourceLoaderProps<T> {
  resource: ResourceWithData<T> | ResourceWithDocs<T>;
  errorMessage?: string;
  errorComponent?: ReactNode;
  loadingMessage?: string;
  loadingComponent?: ReactNode;
  className?: string;
}

export function ResourceLoader<T>({
  resource,
  errorMessage,
  errorComponent,
  loadingMessage,
  loadingComponent,
  className,
  children,
}: PropsWithChildren<IResourceLoaderProps<T>>) {
  return (
    <div className={className}>
      {resource.error
        ? errorComponent ?? (
            <div className={resourceLoaderErrorCss}>
              {errorMessage ??
                'Unable to load your data. Please try again later.'}
            </div>
          )
        : resource.result &&
          (('docs' in resource.result && resource.result.docs) ||
            ('data' in resource.result && resource.result.data))
        ? children
        : loadingComponent ?? (
            <div className={resourceLoaderLoadingCss}>
              <Spinner
                type="disk"
                size="24px"
                className={resourceLoaderLoadingSpinnerCss}
              />
              {loadingMessage ?? 'Loading...'}
            </div>
          )}
    </div>
  );
}
