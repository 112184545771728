import { PropsWithChildren, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useFocusManagerCleanupUserLocks } from '@features/canvas/services/FocusManager/hooks';
import { useSessionId } from 'src/utils/resource.hooks';
import { useUserId } from 'src/utils/userContent/hooks';
import { useEnsureEnrollment } from 'src/app/pages/FlowInstanceAndSessionHandler/hooks';

export default function FlowInstanceAndSessionHandlerPage({
  children,
}: PropsWithChildren<unknown>) {
  const sessionId = useSessionId();
  const isEnrollmentReady = useEnsureEnrollment();

  const userId = useUserId();
  const cleanupLocks = useFocusManagerCleanupUserLocks();
  useEffect(
    function deleteAllLocksOnSessionLeave() {
      if (!sessionId) {
        cleanupLocks(userId);
      }
    },
    [cleanupLocks, sessionId, userId],
  );

  if (isEnrollmentReady) {
    return children ?? <Outlet />;
  }

  return null;
}
