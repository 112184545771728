import { useMemo, useSyncExternalStore } from 'react';
import {
  collection,
  doc,
  documentId,
  DocumentSnapshot,
  FirestoreError,
  getDocs,
  getFirestore,
  orderBy,
  query,
  QueryConstraint,
  QuerySnapshot,
  where,
} from '@firebase/firestore';
import {
  CollectionQueryArgs,
  FirestoreFilterArg,
} from 'src/services/database/types';
import { UUID } from 'src/@types/common';
import {
  buildSimpleQuery,
  composeQueryConstraints,
  useMemoizedQueryArgs,
} from 'src/services/database/utils';
import { getPathForFlowInstances } from 'src/services/database/paths';
import { useFirestoreQuerySubscription } from 'src/services/database/useFirestoreQuerySubscription';
import { useFirestoreDocSubscription } from 'src/services/database/useFirestoreDocSubscription';
import { FlowInstanceConverter } from 'src/services/database/FlowInstances/converter';
import { FlowInstanceDto } from 'src/services/database/FlowInstances/dto/flowInstance.dto';
import { getLocalized } from 'src/utils/i18n';
import { getFlowDefinitionDoc } from 'src/services/database/FlowDefinitions/getters';
import { useFlowDefinitionId, useWorkspaceId } from 'src/utils/resource.hooks';
import { AclResource } from 'src/services/acl/aclResource';
import { useCanAccessResource } from 'src/services/acl/aclApi';
import {
  Enrollment,
  useEnrollmentCol,
} from 'src/services/database/Enrollments';
import { Resource } from 'src/utils/resource';
import { useUserId } from 'src/utils/userContent/hooks';

const DEFAULT_QUERY: CollectionQueryArgs<FlowInstanceDto> = {
  sort: {
    key: 'createdAt',
    order: 'desc',
  },
};

function prepareQuery(queryArg: CollectionQueryArgs<FlowInstanceDto>) {
  const { filterBy, filterByIds, sort } = queryArg || {};
  const constraints: QueryConstraint[] = [];

  if (filterByIds && filterByIds.length > 0) {
    // Firebase throws when filtering for 'in' with an empty array
    constraints.push(where(documentId(), 'in', filterByIds));
  }
  if (filterBy) {
    filterBy
      .filter(
        function noUndefinedFilterCriteria(
          condition,
        ): condition is FirestoreFilterArg<Partial<FlowInstanceDto>> {
          return condition?.value !== undefined;
        },
      )
      .forEach((condition) => {
        constraints.push(where(condition.key, condition.op, condition.value));
      });
  }
  if (sort) {
    constraints.push(orderBy(sort.key, sort.order));
  }

  return query(
    collection(getFirestore(), getPathForFlowInstances()),
    ...constraints,
  );
}

/**
 * Async function to get all flow instances. Mainly used in the Admin section
 * as of now.
 * @param queryArg - Optional sort and filter settings. Defaults to creation time, descending.
 */
export async function queryFlowInstances(
  queryArg: CollectionQueryArgs<FlowInstanceDto>,
) {
  const collectionQuery = prepareQuery(queryArg).withConverter(
    FlowInstanceConverter,
  );
  return await getDocs(collectionQuery);
}

/**
 * @param queryArg - Optional sort and filter settings. Defaults to creation time, descending.
 * @returns Firestore collection
 */
export function useFlowInstanceCol(
  queryArg: CollectionQueryArgs<FlowInstanceDto> = DEFAULT_QUERY,
) {
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);
  const filterQuery = useMemo(
    () => prepareQuery({ filterBy, filterByIds, sort }),
    [filterBy, filterByIds, sort],
  );

  const store = useFirestoreQuerySubscription(
    FlowInstanceConverter,
    filterQuery,
  );
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(() => ({ result, error: store.error }), [result, store.error]);
}

/**
 * Retrieves a flow instance document from Firestore async.
 */
export function useFlowInstanceDoc(flowInstanceId: UUID): {
  result: DocumentSnapshot<FlowInstanceDto> | undefined;
  error: FirestoreError | undefined;
} {
  const query = useMemo(
    () =>
      flowInstanceId
        ? doc(getFirestore(), getPathForFlowInstances(flowInstanceId))
        : undefined,
    [flowInstanceId],
  );
  const store = useFirestoreDocSubscription(FlowInstanceConverter, query);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(
    () => ({
      result,
      error: store.error,
    }),
    [result, store.error],
  );
}

/**
 * Get the next default name for a flow instance in the format
 * `flowDefinitionTitle N` where N is a number
 * @param flowDefinitionId - The ID of the flow definition
 * @param workspaceId - The ID of the workspace where the flow instance is created
 */
export async function getFlowInstanceGeneratedName(
  flowDefinitionId: UUID,
  workspaceId: UUID,
) {
  const flowDefinitionDoc = await getFlowDefinitionDoc(flowDefinitionId);
  const flowDefinitionTitle = getLocalized(flowDefinitionDoc.data()?.title);

  if (!flowDefinitionTitle) {
    return;
  }
  // Get all instances for this workspace + flow definition ID
  // to determine the default name
  const { docs } = await getDocs(
    query(
      collection(getFirestore(), getPathForFlowInstances()),
      ...composeQueryConstraints(
        buildSimpleQuery<FlowInstanceDto>({
          flowDefinitionId,
          workspaceId,
        }),
      ),
    ),
  );

  const nameReg = new RegExp(`${flowDefinitionTitle} \\d+`, 'i');
  const instancesForThisFlow: number[] =
    docs
      ?.map((doc) => {
        const data = doc.data();
        if (
          !data ||
          data.flowDefinitionId !== flowDefinitionId ||
          !data.name ||
          !nameReg.test(data.name)
        ) {
          return;
        }

        const nameNumber = data.name.match(/\d+$/);
        return nameNumber ? Number.parseInt(nameNumber[0], 10) : 0;
      })
      .filter((num): num is number => {
        return num !== undefined && !Number.isNaN(num) && num > 0;
      }) ?? [];

  const maxInstanceNumber = Math.max(0, ...instancesForThisFlow);
  return `${flowDefinitionTitle} ${maxInstanceNumber + 1}`;
}

/**
 * Fetches all flow instances for a given flow definition. Defaults the flow
 * definition ID to the current flow definition, if present.
 * @param flowDefinitionIdProp - The ID of the flow definition
 * @returns A Firestore collection reference.
 */
export function useFlowInstances(
  flowDefinitionIdProp?: UUID,
): Resource<QuerySnapshot<FlowInstanceDto>> {
  const userId = useUserId();
  const flowDefinitionIdInContext = useFlowDefinitionId();
  const flowDefinitionId = flowDefinitionIdProp ?? flowDefinitionIdInContext;
  const workspaceId = useWorkspaceId();
  const hasAccessToFlowInstanceList = useCanAccessResource(
    AclResource.FlowInstanceList,
  );

  const flowInstanceQuery = useMemo(
    () =>
      flowDefinitionId
        ? buildSimpleQuery<FlowInstanceDto>(
            {
              flowDefinitionId,
              workspaceId,
            },
            {
              createdAt: 'desc',
            },
          )
        : {},
    [flowDefinitionId, workspaceId],
  );

  const flowInstances = useFlowInstanceCol(flowInstanceQuery);

  const enrollmentQuery = useMemo(
    () =>
      buildSimpleQuery<Enrollment>({
        flowDefinitionId,
        creatorId: userId,
      }),
    [flowDefinitionId, userId],
  );
  const enrollments = useEnrollmentCol(enrollmentQuery);

  return useMemo(() => {
    // TODO [KS] If user doesn't have access to flow instance list, we are
    //  filtering by enrollments. This filtering should be done in the backend,
    //  but currently it's not possible to filter by enrollments in Firestore.

    if (hasAccessToFlowInstanceList || !flowInstances.result?.docs) {
      return flowInstances;
    }

    if (!enrollments.result?.docs) {
      return {
        result: undefined,
        error: undefined,
      };
    }

    const flowInstanceIdsByEnrollments = new Set<string>();
    enrollments.result.docs.forEach((doc) => {
      const enrollment = doc.data() as Enrollment;
      flowInstanceIdsByEnrollments.add(enrollment.flowInstanceId);
    });

    const filteredResult = flowInstances.result.docs.filter((doc) =>
      flowInstanceIdsByEnrollments.has(doc.data().id),
    );

    return {
      ...flowInstances,
      result: {
        ...flowInstances.result,
        docs: filteredResult,
      } as QuerySnapshot<FlowInstanceDto>,
    };
  }, [flowInstances, enrollments, hasAccessToFlowInstanceList]);
}
