import { Helmet } from 'react-helmet-async';
import { CSSProperties } from 'react';
import { typography } from 'src/styles/typography.css';
import { Path } from 'src/app/router/routes';
import { ButtonPill } from 'src/app/components/shared/ButtonPill';
import { Flex } from '../components/shared/Flex';
import { TypedLink } from '../router';

interface IError404Props {
  message?: string;
  description?: string;
  style?: CSSProperties;
}

export default function Error404({
  message = '4😢4',
  description = 'There is nothing here.',
  style,
}: IError404Props) {
  return (
    <>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <Flex
        direction="column"
        style={{
          width: '100vw',
          maxWidth: '520px',
          height: '100vh',
          margin: '0 auto',
          textAlign: 'center',
          ...style,
        }}
        gap="1.5rem"
      >
        <h1 className={typography.h2}>{message}</h1>
        <p>{description}</p>
        <TypedLink to={Path.Root} replace={false}>
          <ButtonPill intent="primary">Go to homepage</ButtonPill>
        </TypedLink>
      </Flex>
    </>
  );
}
