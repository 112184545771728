import { PropsWithChildren } from 'react';
import { useCanAccessResource } from 'src/services/acl/aclApi';
import { AclResource } from 'src/services/acl/aclResource';
import { useEnrollmentResource } from 'src/services/database/Enrollments';
import Error404 from 'src/app/pages/Error404';

/**
 * Guard component that checks if the user has access to the flow instance, by
 * checking if the user has full ACL access to the flow instance list, or if the
 * user has access to the flow instance by already having an enrollment.
 */
export function FlowInstanceAclGuard({ children }: PropsWithChildren) {
  const hasFullAccess = useCanAccessResource(AclResource.FlowInstanceList);
  const enrollment = useEnrollmentResource();

  if (
    !hasFullAccess &&
    Array.isArray(enrollment.result?.docs) &&
    enrollment.result?.docs.length === 0
  ) {
    return <Error404 message="Unable to find this flow" />;
  }

  return hasFullAccess ||
    (enrollment.result?.docs && enrollment.result.docs.length > 0) ? (
    <>{children}</>
  ) : null;
}
