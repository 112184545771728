import {
  IsArray,
  IsBoolean,
  IsEnum,
  IsNumber,
  IsOptional,
} from 'class-validator';
import { LocaleMap, UUID } from 'src/@types/common';
import { SidebarPage } from 'src/@types/session';
import { IsFirestoreDocumentId } from 'src/services/database/validators/validators';
import { NudgeTextDto } from './NudgeText.dto';
import { NudgeVideoDto } from './NudgeVideo.dto';

/**
 * Defines an activity in the session's facilitation process.
 *
 * The activity definition currently supports nudge text and video,
 * but can be freely extended later with e.g. outcome selection, toggling UI elements etc.
 */
export class SessionActivityDto {
  /**
   * Array index of the activity.
   * This might be required because Firestore doesn't guarantee array order.
   */
  @IsNumber()
  readonly order: number;

  /**
   * The sidebar page that should be displayed when this activity is active.
   * If not specified, the sidebar status won't be changed.
   */
  @IsOptional()
  @IsEnum(SidebarPage)
  activeSidebar?: SidebarPage;

  /**
   * If true, the user can select outcomes on the canvas when this activity is active.
   * If outcome selection should span multiple activities, each activity should have this set to `true`.
   *
   * When outcome selection is active, moving and editing canvas items is disabled.
   */
  @IsOptional()
  @IsBoolean()
  readonly canSelectCanvasOutcomes?: boolean;

  /**
   * Array of canvas item `semanticId`s that should be highlighted on the canvas during this activity.
   */
  @IsOptional()
  @IsArray()
  @IsFirestoreDocumentId({ each: true })
  readonly highlightedCanvasItems?: UUID[];

  /**
   * Optional text displayed in the nudge popover.
   */
  @IsOptional()
  readonly nudgeText?: LocaleMap<NudgeTextDto>;

  /**
   * Optional video for the activity.
   */
  @IsOptional()
  readonly nudgeVideo?: LocaleMap<NudgeVideoDto>;

  constructor(dto: SessionActivityDto) {
    this.order = dto.order;
    this.activeSidebar = dto.activeSidebar;
    this.canSelectCanvasOutcomes = dto.canSelectCanvasOutcomes;
    this.highlightedCanvasItems = dto.highlightedCanvasItems;
    this.nudgeText = dto.nudgeText;
    this.nudgeVideo = dto.nudgeVideo;
  }
}
