import { PropsWithChildren } from 'react';
import { ResourceLoader } from 'src/app/components/shared/ResourceLoader/ResourceLoader';
import Error404 from 'src/app/pages/Error404';
import { useSessionDefinition } from 'src/services/database/FlowDefinitions/CanvasSessions';
import { useFlowDefinitionDoc } from 'src/services/database/FlowDefinitions/getters';
import { useCanEditOrDuplicateFlowDefinition } from 'src/utils/flow/hooks';
import { useFlowDefinitionId, useSessionId } from 'src/utils/resource.hooks';

/**
 * Fetches the flow definition, and renders `children` through `<ResourceLoader />` if:
 * - the session is set to be visible in the flow,
 * - or the user has permission to edit the flow.
 * Otherwise, a 404 page is rendered.
 */
export function SessionAccessGuard({ children }: PropsWithChildren<unknown>) {
  const flow = useFlowDefinitionDoc(useFlowDefinitionId());
  const canEditSession = useCanEditOrDuplicateFlowDefinition();
  const session = useSessionDefinition(useFlowDefinitionId(), useSessionId());

  return (
    <ResourceLoader resource={flow}>
      {canEditSession || session?.isVisible ? children : <Error404 />}
    </ResourceLoader>
  );
}
